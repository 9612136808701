import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Zoom from "react-medium-image-zoom"
import firebase from "gatsby-plugin-firebase"
import { Flex, Box } from "@chakra-ui/core"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1, H3 } from "../components/Typography"
import ShareButtons from "../components/ShareButtons"

const wrapperStyle = {
  width: "100%",
  height: "100%",
  margin: "auto",
}

export default function Template({ data }) {
  const { classified } = data
  const { title, description, fields, featuredImageUrl, classifiedCategories } =
    classified || {}
  const { date, slug } = fields || {}
  useEffect(() => {
    firebase.analytics().setCurrentScreen(`classifieds/${title}`)
  }, [])

  return (
    <Layout>
      <SEO title={title} />
      <Flex marginBottom={8} align="center" justify="center" textAlign="center">
        <H1>{title}</H1>
      </Flex>
      <Box mb={8}>
        <Zoom>
          <Flex
            align="center"
            justify="center"
            flexGrow="1"
            width="90vw"
            maxW="1120px"
          >
            {featuredImageUrl?.childImageSharp?.fluid && (
              <Img
                fluid={featuredImageUrl.childImageSharp.fluid}
                alt="featuredImage"
                style={wrapperStyle}
              />
            )}
          </Flex>
        </Zoom>
      </Box>
      <br />
      <H3>
        {date} &nbsp; &bull; &nbsp; {classifiedCategories?.name}
      </H3>
      <br />
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Box mt={4}>
        <Box>&bull; Location: {classified.location}</Box>
        <Box>&bull; Contact Person: {classified.contactName}</Box>
        <Box>&bull; Contact Number: {classified.contactNumber}</Box>
      </Box>
      <Box mt={3} />
      <ShareButtons
        title={title}
        url={`https://www.laudiumtoday.co.za/classifieds/${slug}`}
        twitterHandle={"LaudiumToday"}
        tags={["LaudiumToday", "news"]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    classified(id: { eq: $id }) {
      classifiedCategories {
        name
      }
      description
      id
      fields {
        date(formatString: "DD MMMM YYYY")
        slug
      }
      featuredImageUrl {
        childImageSharp {
          fluid(maxHeight: 750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      description
      location
      contactName
      contactNumber
      price
      title
    }
  }
`
